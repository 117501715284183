.mob {
  display: none;
}

.best-hotels__swiper {
  width: 100%;
}

@media (max-width: 749px) {
  .mob {
    display: block;
  }

  .swiper {
    padding-bottom: 15px;
  }

  .hotel-slide {
    padding: 15px;
    position: relative;
    height: 202px;
  }

  .best-hotels__rect {
    background-color: #aea17f;
    position: absolute;
    top: 0;
    right: 0;
    height: 155px;
    width: 78px;
    border-radius: 20px;
    z-index: -1;
  }

  .rect-2 {
    top: auto;
    bottom: 15px;
    left: 0;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background: #777777;
    opacity: 1;
  }

  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 2px;
  }

  .swiper-pagination-bullet-active {
    background: #d9d9d9;
    width: 10px;
    height: 10px;
  }

  .swiper-pagination-horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
