@import url("/src/vendor/fonts/fonts.css");
@import url("/src/vendor/normalize.css");

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.best-page {
  max-width: 1440px;
  margin: 0 auto;
  text-align: left;
  font-family: "Inter", "Arial", sans-serif;
}

h1,
h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  color: #3f3100;
}

p {
  margin: 0;
  color: #fff;
}

a {
  text-decoration: none;
  color: #fff;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.t-20 {
  font-size: 20px;
  line-height: 24px;
}

.t-16 {
  font-size: 16px;
  line-height: 20px;
}

.text-center {
  text-align: center;
}

.col-brown {
  color: #3f3100;
}

.col-white {
  color: #fff;
}

.mg-top {
  margin-top: 60px;
}

.best-privacy {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 0 60px;
}

.best-privacy p {
  color: #3F3100;
}

.best-privacy h1,
.best-privacy h2 {
  text-align: start;
  margin: 20px 0 10px;
}

@media (max-width: 1100px) {
  .mg-top {
    margin-top: 70px;
  }

  .best-privacy {
    padding: 0 20px;
  }
}

@media (max-width: 749px) {
  h1,
  h2 {
    font-size: 20px;
    line-height: 24px;
  }

  .mg-top {
    margin-top: 20px;
  }

  .t-20,
  .t-16 {
    font-size: 14px;
    line-height: 17px;
  }

  .best-privacy {
    padding: 0 10px;
  }
}
