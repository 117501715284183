.best-main__cover {
  background: url("/src/images/main-cover.webp") center / cover no-repeat;
  min-height: 510px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: start;
  align-items: end;
  padding: 40px;
}

.best-main__header {
  font-size: 40px;
  line-height: 60px;
  color: #fff;
  background-color: #3f3100;
  border-radius: 30px;
  padding: 30px 20px;
  max-width: 811px;
}

.best-main__text {
  background-color: #3f3100;
  border-radius: 30px;
  padding: 30px 20px;
  max-width: 725px;
  color: #fff;
}

@media (max-width: 1100px) {
  .best-main__header {
    font-size: 30px;
    line-height: 35px;
  }

  .best-main__cover {
    padding: 20px 20px 20px 80px;
  }
}

@media (max-width: 749px) {
  .best-main__cover {
    background: url("/src/images/main-cover-mob.webp") center / cover no-repeat;
    height: 399px;
    padding: 10px 0;
    justify-content: space-between;
  }

  .best-main__header {
    font-size: 19px;
    line-height: 28px;
    border-radius: 0;
    padding: 10px;
  }

  .best-main__text {
    border-radius: 0;
    font-size: 16px;
    line-height: 24px;
    padding: 10px;
  }
}
