.best-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.8);
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 9999;
  padding: 200px 25px 25px;
}

.best-popup-cookie {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 541px;
  min-height: 285px;
  z-index: 3;
  background: #513a00;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.best-popup__body {
  width: 100%;
  max-width: 541px;
  min-height: 323px;
  position: relative;
  background: #513a00;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.best-popup__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.best-popup__text {
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.best-popup__btn {
  width: 100%;
  max-width: 171px;
  height: 40px;
  cursor: pointer;
  background: #aea17f;
  border: none;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.best-popup__btn-container {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
}

.best-popup__btn-cookie {
  width: 100%;
  max-width: 178px;
  height: 40px;
  cursor: pointer;
  background: #aea17f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 20px;
}

.disabled {
  display: none;
}

@media (max-width: 749px) {
  .best-popup__header {
    font-size: 34px;
    line-height: 45px;
  }

  .best-popup__body {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .best-popup__btn-container {
    flex-direction: column;
    align-items: center;
  }
}
