.best-about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  padding: 0 154px;
}

.best-about__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  max-width: 495px;
}

.best-about__left {
  justify-self: left;
  padding-top: 40px;
  margin-bottom: 10px;
}

.best-about__right {
  justify-self: end;
  padding-bottom: 40px;
}

.best-about__text-container {
  background: rgba(93, 67, 1, 0.5);
  box-shadow: 10px 10px 15px rgba(93, 67, 1, 0.25);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-items: start;
  flex-grow: 1;
}

.best-about__list {
  display: flex;
  flex-direction: column;
  gap: 3px;
  list-style: auto;
  padding-left: 25px;
}

.best-about__img {
  width: 100%;
  height: auto;
}

@media (max-width: 1100px) {
  .best-about {
    padding: 0 30px;
  }
}

@media (max-width: 749px) {
  .best-about {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 10px;
    align-items: center;
  }

  .best-about__container {
    gap: 20px;
    max-width: 550px;
    width: 100%;
  }
}
