.best-footer {
  display: flex;
  flex-direction: column;
  background-color: #241a00;
  padding: 20px 60px 60px;
  align-items: center;
  gap: 30px;
}

.best-footer__disclaimer {
  max-width: 937px;
}

.best-footer__text-container {
  display: grid;
  grid-template-columns: 1fr 0.9fr 1fr;
  gap: 20px;
  margin-top: 30px;
}

.best-footer__flex-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}

.best-footer__link {
  background-color: #aea17f;
  border-radius: 20px;
  min-height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 289px;
  padding: 10px 30px;
}

.best-footer__policy {
  display: flex;
  justify-content: center;
  align-items: center;
}

.best-footer__bottom-container {
  display: flex;
  max-width: 350px;
  justify-content: space-between;
  width: 100%;
}

.best-footer__links-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.mob {
  display: none;
}

@media (max-width: 1100px) {
  .best-footer {
    padding: 20px 20px 40px;
  }
  .best-footer__text-container {
    grid-template-columns: 1.1fr 0.9fr 1.1fr;
  }
}

@media (max-width: 749px) {
  .pc {
    display: none;
  }

  .mob {
    display: flex;
  }

  .best-footer__text-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0;
  }

  .best-footer__bottom-container {
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
  }
}
