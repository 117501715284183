.best-hotels__list {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 40px;
  align-items: center;
}

.best-hotels__item {
  padding: 20px 60px;
  display: grid;
  grid-template-columns: 2fr 1.2fr;
  gap: 60px;
  min-height: 717px;
}

.img-1 {
  background: url("/src/images/JW-Marriott-Bucharest-Grand-Hotel-full.webp")
    center / contain no-repeat;
}

.img-2 {
  background: url("/src/images/Radisson-BLU-Bucharest-full.webp") center /
    contain no-repeat;
}

.img-3 {
  background: url("/src/images/Sheraton-Bucharest-Hotel-full.webp") center /
    contain no-repeat;
}

.img-4 {
  background: url("/src/images/Qosmo-Brasov-Hotel-full.webp") center / contain
    no-repeat;
}

.bg-brown {
  background-color: #241a00;
}

.bg-light {
  background-color: #513a00;
  grid-template-columns: 1.2fr 2fr;
}

.best-hotels__info {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
}
.best-hotels__perks {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-self: start;
  margin-top: 10px;
  margin-bottom: 5px;
}

.best-hotels__perk {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #fff;
}

.best-hotels__pad {
  padding-bottom: 20px;
}

.best-hotels__link {
  background-color: #aea17f;
  width: 100%;
  max-width: 232px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-top: auto;
}

.best-hotels__img-swiper {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

@media (max-width: 1100px) {
  .best-hotels__item {
    padding: 15px;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
  }
}

@media (max-width: 749px) {
  .best-hotels__item {
    display: flex;
    flex-direction: column;
    max-width: 550px;
    width: 100%;
  }

  .best-hotels__img {
    display: none;
  }

  .best-hotels__list {
    gap: 30px;
  }
}
