.best-deals__swiper {
  width: 100%;
  max-width: 550px;
  padding-bottom: 30px;
}

.best-deals__body {
  margin: 60px 60px 0;
}

.best-deals__list {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.best-deals__item {
  max-width: 378px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  background-color: #513a00;
  border-radius: 20px;
  padding: 15px 20px 40px;
  height: auto;
}

.best-deals__img-container {
  margin: 10px;
  position: relative;
  padding: 10px 0;
}

.best-deals__rect {
  background-color: #aea17f;
  position: absolute;
  top: 0;
  right: -10px;
  height: 70%;
  width: 78px;
  border-radius: 20px;
}

.deals-rect-2 {
  top: auto;
  bottom: 0;
  left: -10px;
}

.best-hotels__img-swiper {
  position: relative;
  z-index: 1;
}

.best-deals__price {
  display: flex;
  justify-content: space-between;
  max-width: 230px;
  width: 100%;
  margin: 30px 0 30px;
}

.best-deals__old-price {
  color: #ff0000;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  text-decoration: line-through;
}

.best-deals__new-price {
  color: #00ff47;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

@media (max-width: 1100px) {
  .best-deals__body {
    margin: 40px 20px 0;
  }
}

@media (max-width: 749px) {
  .best-deals__list {
    display: none;
  }

  .deal-slide {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    background-color: #513a00;
    border-radius: 20px;
    padding: 15px 20px 40px;
    height: auto;
  }

  .best-deals__swiper .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -2px;
  }

  .best-deals__body {
    margin: 20px 5px 0;
  }

  .best-deals {
    margin-top: 50px;
  }
}
