@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Inter_18pt-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./Inter_18pt-Bold.ttf") format("truetype");
}
